/* src/App.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  background-color: #282a37;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 70px; /* Add margin at the bottom */
}

header {
  color: white;
  text-align: center;
  width: 100%;
  font-size: 44px;
  margin-top: 50px; /* Reduce space above the header */
}

h1 {
  margin: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.project-panel-link {
  text-decoration: none;
  color: inherit;
}

.project-panel {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  border: 1px solid #63738a;
  cursor: pointer;
  transition: transform 0.2s;
  width: 300px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-panel:hover {
  transform: scale(1.05);
}

.project-panel h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.project-panel p {
  color: #b6b7b9;
  font-weight: 500;
}

.technologies {
  margin-top: 10px;
  font-family: 'Roboto Mono', sans-serif;
}

.technologies .tech {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #393c47;
  color: #f281c5;
  border-radius: 20px;
  font-size: 12px;
}

/* Change tech color on panel hover */
.project-panel:hover .tech {
  color: #81cce3;
}
